/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { OffersData } from "data";
import Wrapper from "components/Wrapper";
import OfferListItem, { DummyOfferListItem } from "components/OfferListItem";
import { stutter } from "helpers/animation";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import { getBrowserBreakpointValue } from "helpers/utils";

import styles from "./styles.scss";

const NUM_DUMMY_CARDS = 6;
const COLUMN_BREAKPOINT = 500;

const OfferListView = () => {
  const t = useTranslate();
  const { width: browserWidth } = useBrowserDimensions();
  const {
    content: { offerlistview },
  } = useContext(StoreInfoContext);
  const offers = useData(OffersData);

  if (offers.state !== "LOADED") {
    return null;
  }

  const breakpointMedium = getBrowserBreakpointValue(styles, "medium");
  const _stutter = stutter(100, 100);
  const count = offers.state === "LOADED" ? offers.data.length : 0;

  return (
    <Wrapper>
      <Helmet
        title={offerlistview.pageTitle ?? ""}
      />

      {offerlistview.heading && <h1 className={styles.heading}>{offerlistview.heading}</h1>}

      {/* eslint-disable react/no-danger */}
      {offerlistview.description &&
        <p
          className={cn(
            styles.description,
            { [styles.column]: offerlistview.description.length > COLUMN_BREAKPOINT }
          )}
          dangerouslySetInnerHTML={{ __html: offerlistview.description }}
        />
      }
      {/* eslint-enable react/no-danger */}

      <div className={cn(styles.items, styles[`count__${count}`])}>
        {(offers.state === "LOADED" && offers.data.length > 0) &&
          (browserWidth < breakpointMedium) ? (
            <div className={styles.mobileCol}>
              {offers.data.map((x, i) => (
                <div
                  key={i}
                  style={{ animationDelay: _stutter(i) }}
                  className={styles.item}
                >
                  <OfferListItem count={count} item={x} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className={styles.leftCol}>
                {offers.data.map((x, i) => (
                  (i % 2 === 0) && (
                    <div
                      key={i}
                      style={{ animationDelay: _stutter(i) }}
                      className={styles.item}
                    >
                      <OfferListItem count={count} item={x} />
                    </div>
                  )
                ))}
              </div>
              <div className={styles.rightCol}>
                {offers.data.map((x, i) => (
                  (i % 2 === 1) && (
                    <div
                      key={i}
                      style={{ animationDelay: _stutter(i) }}
                      className={styles.item}
                    >
                      <OfferListItem count={count} item={x} />
                    </div>
                  )
                ))}
              </div>
            </>
          )
        }

        {offers.state === "LOADED" && offers.data.length < 1 &&
          <div className={styles.no_items}>
            <h2>{t("OFFERS.EMPTY")}</h2>
          </div>
        }

        {offers.state !== "LOADED" &&
          [...new Array(NUM_DUMMY_CARDS)]
            .map((x, i) => (
              <div
                key={"dummyNews_" + i}
                style={{
                  animationDelay: _stutter(i),
                }}
                className={styles.item}
              >
                <DummyOfferListItem item={x} />
              </div>
            ))
        }
      </div>
    </Wrapper>
  );
};

export default OfferListView;
