/* @flow */

import type { History } from "react-router";

import type { Node as ReactNode } from "react";
import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import Banner from "components/Banner";
import Notice from "components/Notice";
import { useData } from "crustate/react";
import useCookie from "helpers/use-cookie";
import { getFavicons } from "helpers/get-meta";
import { isShopOrAccountRoute } from "helpers/utils";
import { TransactionsData, DistrictInfoData, TinkProvidersData, CustomerData, CmsData } from "data";
import useOnFullMenuHiddenRoute from "helpers/use-on-full-menu-hidden-route";
import { RecruitAFriendModal } from "components/RecruitAFriend";
import TinkProvidersModal from "components/TinkProvidersModal";
import { NotificationBar } from "@crossroads/ui-components";
import CogIcon from "icons/cog.svg";
import { PREVIEW_PAGESIZE as TRANSACTIONS_PREVIEW_PAGESIZE } from "state/transactions";
import { memberTagList } from "queries";
import { Title } from "components/UiComponents";
import CookieConsent from "components/CookieConsent";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  location: Location,
  history: History,
};

const App = ({ children, location, history }: Props) => {
  const t = useTranslate();
  const container = useRef(null);
  const onHomeView = location.pathname === "/" || location.pathname.includes("index.html");
  const { info, configuration, routes, content: { homeview, appheader, transactionsview } } =
    useContext(StoreInfoContext);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const [onIE, setOnIE] = useState(false);
  const onCheckout = location.pathname.includes((routes.checkoutView && routes.checkoutView.url) ?? "");
  const onSuccess = location.pathname === routes.checkoutSuccessView?.url;
  const onEarn = location.pathname.endsWith((routes.earnView && routes.earnView.url) ?? "");
  const showTink = useMemo(() =>
    (routes.accountTinkView && routes.accountTinkView.toggle) ?? false, []);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);
  const { value: IEAccepted, setValue: setIEAccepted } =
  useCookie("ie_notice_accepted", false, { expires: 365 });
  const flag = transactionsview.allTransactions === true ? "ALL_INCLUDING_CANCELED_AND_REFUND" : null;
  const customerData = useData(CustomerData);
  const [hasNoOwnPoints, setHasNoOwnPoints] = useState(false);
  const [memberHasNoOwnPointsTag, setMemberHasNoOwnPointsTag] = useState(false);
  const [hasBeenBlocked, setHasBeenBlocked] = useState(false);
  const client = useClient();
  const onFullMenuHiddenRoute = useOnFullMenuHiddenRoute();
  const questionnaireViewUrl = routes.questionnaireView && routes.questionnaireView.url ? routes.questionnaireView.url : "";

  const hasMobileSubNav = [
    questionnaireViewUrl,
    questionnaireViewUrl + "/:slug",
  ].some(route => location.pathname.includes(route));

  /** Check if the client is using an unsupported browser (IE) */
  useEffect(() => {
    if (window.document.documentMode) {
      setOnIE(true);
    }

    client(memberTagList).then(({ memberTagList }) => {
      if (memberTagList.find(tag => Object.values(tag).includes("No own points"))) {
        setMemberHasNoOwnPointsTag(true);
      }
    });

    // Members who doesn't have any points on
    // their own shouldn't have access to the checkout
    if (
      (customerData.state === "LOGGED_IN" &&
      customerData.data &&
      customerData.data.awardit.ownPoints === 0 &&
      customerData.data.awardit.groupPoints > 0 &&
      customerData.data.awardit.activePoints === 0) ||
      memberHasNoOwnPointsTag
    ) {
      setHasNoOwnPoints(true);
    }

    if (customerData.state === "LOGGED_IN" &&
      customerData.data &&
      customerData.data.memberPoints &&
      customerData.data.memberPoints.redeemBlocked !== null &&
      customerData.data.memberPoints.redeemBlocked !== undefined &&
      customerData.data.memberPoints.redeemBlocked
    ) {
      setHasBeenBlocked(true);
    }
  }, [customerData, client]);

  usePreserveScrollPosition(history);

  return (
    <TinkProvidersData.Provider load={showTink}>
      <DistrictInfoData.Provider>
        <TransactionsData.Provider name="preview" size={TRANSACTIONS_PREVIEW_PAGESIZE} filter={{ transactionFlag: flag }}>
          <CmsData.Provider url="cookie-consent">
            <div
              ref={container} className={cn(
                styles.block,
                { [styles.onCheckout]: onCheckout },
                { [styles.fullMenu]: !onHomeView && !onFullMenuHiddenRoute },
                { [styles.onEarn]: onEarn },
                { [styles.hasMobileSubNav]: hasMobileSubNav }
              )}
            >
              <Helmet
                titleTemplate={`${configuration.pageTitlePrefix || ""} %s ${configuration.pageTitleSuffix || ""}`}
                link={getFavicons(configuration)}
                htmlAttributes={{ lang: info.locale.slice(0, 2) }}
              />

              <div className={styles.content}>
                <AppHeader
                  className={styles.header}
                  subNavOpen={subNavOpen}
                  setSubNavOpen={setSubNavOpen}
                  onHomeView={onHomeView}
                  onCheckout={onCheckout}
                  onSuccess={onSuccess}
                />

                <div className={styles.height} />

                {location.pathname !== routes.termsView?.url &&
                  <CookieConsent />
                }

                <div className={styles.notices}>
                  {onIE && <Notice
                    cookieName="ie_notice_accepted"
                    icon={<CogIcon />}
                    acceptanceText={t("IE_NOTICE")}
                    accepted={IEAccepted}
                    callback={setIEAccepted}
                  />}
                </div>

                <div className={styles.children}>
                  {hasNoOwnPoints &&
                  isShopOrAccountRoute(location.pathname, routes, onFullMenuHiddenRoute) &&
                    <Banner
                      className={styles.bannerWarning}
                    >
                      <Title elem="h2" className={styles.title}>{appheader.noOwnPointsTitle ?? ""}</Title>
                      <p>{appheader.noOwnPointsBody ?? ""}</p>
                    </Banner>
                  }

                  {hasBeenBlocked &&
                    isShopOrAccountRoute(location.pathname, routes, onFullMenuHiddenRoute) &&
                    <Banner
                      className={styles.bannerWarning}
                    >
                      <p>{t("MESSAGE.BLOCKED")}</p>
                    </Banner>
                  }

                  {children}
                </div>

                {!onCheckout &&
                  <AppFooter
                    className={styles.footer}
                    setSubNavOpen={setSubNavOpen} />
                }

                {homeview.notificationBarStatus !== undefined &&
                homeview.notificationBarStatus !== null &&
                homeview.notificationBarStatus === true &&
                homeview.notificationBarText !== "" &&
                !hideNotificationBar &&
                  <NotificationBar
                    className="awardit-notificationBar"
                    text={homeview.notificationBarText}
                    onClose={() => setHideNotificationBar(true)}
                  />
                }

                <RecruitAFriendModal overlayConfig={{ color: "#000", opacity: 0.3 }} />

                {routes.accountTinkView && routes.accountTinkView.toggle !== undefined &&
                routes.accountTinkView.toggle &&
                  <TinkProvidersModal overlayConfig={{ color: "var(--light)", opacity: 0.9 }} />
                }
              </div>
            </div>
          </CmsData.Provider>
        </TransactionsData.Provider>
      </DistrictInfoData.Provider>
    </TinkProvidersData.Provider>
  );
};

export default App;
