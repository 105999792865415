/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useSendMessage } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import SystemMessages from "components/SystemMessages";
import { getFavicons } from "helpers/get-meta";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";
import CookieConsent from "components/CookieConsent";
import Button from "components/Button";
import { CmsData } from "data";
import cn from "classnames";
import { setMode, MODE } from "state/view-mode";

import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
  location: Location,
};

const GuestApp = ({ children, location }: Props) => {
  const {
    info,
    configuration,
    routes,
    content: { loginview, appfooter },
  } = useContext(StoreInfoContext);

  const loginViewLogoSrc = loginview.logo || "";
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const consentActivated = (configuration.showCookieConsent !== null ||
    configuration.showCookieConsent !== undefined) &&
    configuration.showCookieConsent === true;

  return (
    <CmsData.Provider url="cookie-consent">
      <div className={styles.block}>
        <Helmet
          titleTemplate={`${configuration.pageTitlePrefix || ""} %s ${configuration.pageTitleSuffix || ""}`}
          link={getFavicons(configuration)}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <header className={styles.header}>
              <Wrapper className={styles.headerWrapper}>
                <Link to="/">
                  <Logo width={configuration.logoWidthLarge} loginViewLogo={loginViewLogoSrc} />
                </Link>
                <h1>{loginview.heading}</h1>
              </Wrapper>
            </header>
            {children}
            <SystemMessages />
            {location.pathname === routes.loginView?.url &&
              <CookieConsent />
            }
          </div>
          <div className={styles.footer}>
            <div className={styles.footerInner}>
              {loginview.customerServiceButtonUrl && appfooter.copyrightText &&
                <span>{appfooter.copyrightText}</span>
              }

              <nav>
                {consentActivated &&
                  <Button
                    className={cn(
                      { [styles.spacing]: loginview.customerServiceButtonUrl },
                      styles.cookieButton
                    )}
                    onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                  >
                    {t("FOOTER.NEED_HELP.COOKIES")}
                  </Button>
                }
                {loginview.customerServiceButtonUrl &&
                  <a
                    href={loginview.customerServiceButtonUrl}
                    rel="noopener noreferrer" target="_blank"
                  >
                    {loginview.customerServiceButtonText}
                  </a>
                }
                {Boolean(configuration.showPrivacyNotice) &&
                  <a
                    href="/privacy-notice"
                    className={styles.privacyNotice}
                  >
                    {t("FOOTER.LEGAL.PRIVACY_NOTICE")}
                  </a>
                }
              </nav>
            </div>
          </div>
        </div>
      </div>
    </CmsData.Provider>
  );
};

export default GuestApp;
