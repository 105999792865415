/* @flow */

import type { TransactionFlag } from "shop-state/types";

import React, { useContext } from "react";

import { Input, Dropdown, DropdownItem } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import { subMonths } from "date-fns";
import SearchIcon from "icons/search.svg";
import ClearIcon from "icons/close-small.svg";

import styles from "./styles.scss";

export type DateFrom = { key: string, date: ?Date };

type ControlsProps = {
  activeFilter: (TransactionFlag | "ALL"),
  setActiveFilter: (TransactionFlag | "ALL") => void,
  searchValue: string,
  setSearchValue: string => void,
  dateFrom: ?DateFrom,
  setDateFrom: DateFrom => void,
  disabled: boolean,
};

export const FILTER_ALL = "ALL";
const FILTER_IN = "UP";
const FILTER_OUT = "DOWN";
const FILTER_REFUND = "REFUND";

export const DATE_FROM = {
  ALL: "ALL",
  HALF_YEAR: "HALF_YEAR",
  FULL_YEAR: "FULL_YEAR",
};

const Controls = ({
  activeFilter,
  setActiveFilter,
  searchValue,
  setSearchValue,
  dateFrom,
  setDateFrom,
  disabled,
}: ControlsProps) => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);

  const filters = [
    [FILTER_OUT, transactionsview.filterOut],
    [FILTER_IN, transactionsview.filterIn],
    [FILTER_ALL, transactionsview.filterAll],
  ];

  if (transactionsview.allTransactions === true) {
    filters.splice(filters.length - 1, 0, [FILTER_REFUND, transactionsview.filterRefund]);
  }

  return (
    <div className={styles.controls}>
      <div className={styles.left}>
        <Input
          slotLeft={<SearchIcon className={styles.searchIcon} />}
          value={searchValue}
          placeholder={transactionsview.search}
          wrapperClassName={styles.searchInput}
          disabled={disabled}
          slotRight={searchValue &&
            <button
              className={styles.clear}
              aria-label="Clear search input"
              title="Clear search (esc)"
              type="button"
              onClick={() => setSearchValue("")}
            >
              <ClearIcon className={styles.clearIcon} />
            </button>}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>

      <div className={styles.right}>
        <Dropdown
          className={styles.fitlerPicker}
          value={activeFilter}
          disabled={disabled}
          onChange={(value: any) => {
            if (
              value === FILTER_OUT ||
              value === FILTER_IN ||
              value === FILTER_REFUND ||
              value === FILTER_ALL
            ) {
              setActiveFilter(value);
            }
          }}
        >
          {filters.map(item =>
            <DropdownItem key={item[0]} value={item[0]}>{item[1]}</DropdownItem>
          )}
        </Dropdown>

        <Dropdown
          className={styles.datePicker}
          value={dateFrom?.key}
          disabled={disabled}
          placeholder={transactionsview.datePlaceholder}
          onChange={(value: any) => {
            if (value === DATE_FROM.HALF_YEAR) {
              // Set date from half year from now
              setDateFrom({ key: value, date: subMonths(new Date(), 6) });
              return;
            }

            if (value === DATE_FROM.FULL_YEAR) {
              // Set date from half year from now
              setDateFrom({ key: value, date: subMonths(new Date(), 12) });
              return;
            }

            setDateFrom({ key: DATE_FROM.ALL, date: null });
          }}
        >
          <DropdownItem value={DATE_FROM.HALF_YEAR}>{transactionsview.dateHalfYear}</DropdownItem>
          <DropdownItem value={DATE_FROM.FULL_YEAR}>{transactionsview.dateFullYear}</DropdownItem>
          <DropdownItem value={DATE_FROM.ALL}>{transactionsview.dateAll}</DropdownItem>
        </Dropdown>
      </div>

    </div>
  );
};

export default Controls;
