/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import { useLocation } from "react-router";
import { useSendMessage } from "crustate/react";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import { HashLink } from "react-router-hash-link";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  className: string,
};

const AppFooter = ({ className = "" }: Props) => {
  const location = useLocation();
  const t = useTranslate();
  const onHomeView = location.pathname === "/" || location.pathname.includes("index.html");
  const storeInfo = useContext(StoreInfoContext);
  const { routes: { termsView },
    configuration: { helpdeskLink, showCookieConsent, showPrivacyNotice },
  } = storeInfo;
  const sendMessage = useSendMessage();
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  const currentYear = new Date().getFullYear();

  return (
    <footer className={cn(styles.block, className, { [styles.onHomeView]: onHomeView })}>
      <div className={styles.inner}>
        <Wrapper className={styles.wrapper}>
          <div className={cn(styles.section, styles.links)}>
            <div className={styles.left}>
              <Link className={styles.link} to="/contact">
                Kontakt
              </Link>
              <Link className={styles.link} to="/companies">
                Våra bolag
              </Link>
              <Link className={styles.link} to="/about">
                Proffsklubben
              </Link>
              <Link className={styles.link} to="/companies">
                Bli kund
              </Link>
              <HashLink smooth to="/about#faq" className={styles.link}>
                Frågor och svar
              </HashLink>
              {consentActivated &&
                <Button
                  className={styles.link}
                  onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                >
                  {t("FOOTER.NEED_HELP.COOKIES")}
                </Button>
              }
              <Link
                className={styles.link}
                to={termsView?.url}
              >
                {termsView?.title || "Allmänna villkor & personuppgiftspolicy"}
              </Link>
              {Boolean(showPrivacyNotice) &&
                <Link
                  className={cn("awardit-appFooterPrivacyNoticeButton", styles.link)}
                  to="/privacy-notice"
                >
                  {t("FOOTER.LEGAL.PRIVACY_NOTICE")}
                </Link>
              }
            </div>
            <div className={styles.right}>
              {/* <Link
                className={styles.link}
                to={termsView?.url}
              >
                {termsView?.title || "Allmänna villkor & personuppgiftspolicy"}
              </Link> */}
            </div>
          </div>
          <div className={cn(styles.section, styles.infoLinks)}>
            <div className={styles.left}>
              <div className={styles.infoLink}>
                <p>© Saint-Gobain Distribution Sweden AB {currentYear}</p>
              </div>
              {helpdeskLink &&
                <div className={styles.infoLink}>
                  <a
                    className={cn(styles.link, styles.email)}
                    href={helpdeskLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Kontaktformulär kundtjänst
                  </a>
                </div>
              }
              <div className={styles.infoLink}>
                <span className={styles.infoLinkInfo}>
                  08-588 90 702 (Kundtjänst öppen vardagar mellan 9-12 och 14-16)
                </span>
              </div>
            </div>
            <div className={styles.logoWrapper}>
              <img
                className={styles.logo}
                src="/assets/images/proffsklubben-logo-inverted.svg"
                alt="Awardit"
              />
            </div>
          </div>
        </Wrapper>
      </div>
    </footer>
  );
};

export default AppFooter;
