/* @flow */

import type { Mode } from "state/view-mode";
import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { CartMini } from "@crossroads/ui-components";
import cn from "classnames";
import useFormat from "helpers/use-format";
import SystemMessages from "components/SystemMessages";
import Logo from "components/AppHeader/Logo";
import CustomerBadge from "components/CustomerBadge";
import { MODE } from "state/view-mode";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import SubMenu from "components/AppHeader/Large/SubMenu";
import AdminButton from "components/AccountView/AdminButton";
import CartIcon from "icons/cart-alt.svg";

import styles from "./styles.scss";

type Props = {
  className: string,
  mode: Mode,
  setMode: Mode => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  lastVisited: ?string,
  customer: ?Customer,
  logoWidth: string,
  cartCount?: number,
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long", day: "numeric" });
};

const noDimModes = [MODE.NORMAL, MODE.SEARCH, MODE.RECRUIT, MODE.TINK];

const AppHeaderLarge = ({
  mode,
  setMode,
  onHomeView,
  onCheckout,
  lastVisited,
  customer,
  logoWidth,
  onSuccess,
  cartCount,
  className = "",
}: Props) => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const { routes, content: { accountview } } = storeInfo;
  const { formatPoints } = useFormat();
  const nav = navRoutes(ROUTES, storeInfo);
  const showAdminButton = Boolean(accountview.showAdministration) &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;

  const expiringPoints = customer && customer.awardit.expiringPoints > 0 ?
    formatPoints(customer.awardit.expiringPoints) : null;
  const expiringPointsDate = customer && customer.awardit.expiringPointsDate ?
    formatDate(customer.awardit.expiringPointsDate) : null;

  return (
    <div className={styles.container}>
      <div className={cn(styles.block, className, { [styles.fullMenu]: !onHomeView })}>
        <nav className={styles.nav}>
          <div className={styles.logoWrapper}>
            {routes.homeView && routes.homeView.url &&
              <Link className={cn(styles.item, styles.logo)} to={routes.homeView?.url}>
                <Logo
                  width={logoWidth}
                  height="47"
                  onClick={() => setMode(MODE.NORMAL)}
                />
              </Link>
            }
          </div>

          <div className={styles.links}>
            {!onCheckout && nav.map(x => (
              <NavLink
                key={x.url}
                to={x.url}
                activeClassName={styles.active}
                className={styles.item}
              >
                {x.title}
              </NavLink>
            ))}
            {!onCheckout && (
              <NavLink
                to="/companies"
                className={styles.item}
              >
                Våra bolag
              </NavLink>
            )}
          </div>

          <div className={styles.right}>
            {expiringPointsDate && expiringPoints &&
            routes.accountView && routes.accountView.url && (
              <NavLink
                to={routes.accountView.url}
                className={cn(styles.item, styles.expiresNotice)}
              >
                <div className={styles.exclamation}>!</div>
                <div className={styles.notice}>
                  <span className={styles.date}>
                    {`${t("ACCOUNT.EXPIRES")} ${expiringPointsDate}`}
                  </span>
                  <span className={styles.points}>
                    {expiringPoints}
                  </span>
                </div>
              </NavLink>
            )}

            {showAdminButton &&
              <div className={cn(styles.adminBtnContainer, "awardit-adminButton")}>
                {accountview.administration &&
                  <AdminButton text={accountview.administration} />
                }
              </div>
            }

            {routes.accountView && routes.accountView.toggle !== undefined &&
            routes.accountView.toggle && (
              <NavLink
                to={routes.accountView.url}
                activeClassName={styles.active}
                className={cn(styles.item, styles.item__customerBadge)}
              >
                <CustomerBadge customer={customer} />
              </NavLink>
            )}

            {typeof cartCount === "number" && cartCount > 0 && (
              <div
                className={styles.item} onClick={() => {
                  if (cartCount === 0) {
                    return;
                  }

                  setMode(mode === MODE.CART ? MODE.NORMAL : MODE.CART);
                }}
              >
                <CartIcon className={styles.icon} />
                <span className={styles.cartCount}>{cartCount}</span>
              </div>
            )}
          </div>
        </nav>
      </div>

      <SubMenu
        mode={mode}
        setMode={setMode}
        lastVisited={lastVisited}
        onCheckout={onCheckout}
        onSuccess={onSuccess} />

      {!noDimModes.includes(mode) &&
        <div
          className={cn(styles.dim, { [styles.cart]: mode === MODE.CART })}
          onClick={() => setMode(MODE.NORMAL)}
        />
      }

      {mode === MODE.CART && routes.checkoutView && routes.checkoutView.url && (
        <div className={styles.miniCartWrapper}>
          <CartMini
            quoteData={quoteData}
            variant="b2b"
            altUrl={`${routes.checkoutView.url}/cart`}
            ctaUrl={`${routes.checkoutView.url}/shipping`}
            className={cn(styles.desktop, styles.miniCart)}
            onClose={() => setMode(MODE.NORMAL)}
          />
        </div>
      )}

      <div>
        <SystemMessages />
      </div>
    </div>
  );
};

export default AppHeaderLarge;

