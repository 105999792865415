/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";

import React, { useState, useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { resetPassword } from "@crossroads/shop-state/customer";
import { Form, rules, isRequired, isEmail } from "@awardit/formaggio";
import { useSendMessage } from "crustate/react";
import Button from "components/Button";
import { focusInvalidField } from "helpers/utils";
import Field from "components/Field";
import CloseIcon from "icons/close-small.svg";
import styles from "./styles.scss";
import { StoreInfoContext } from "entrypoint/shared";

type ResetPasswordProps = {
  email: string,
};

const initialFormState = {
  email: "",
};

const validation = rules([
  isRequired("email"),
  isEmail("email"),
]);

const ResetPasswordView = () => {
  const t = useTranslate();
  const {
    content: { loginview },
  } = useContext(StoreInfoContext);
  const sendMessage = useSendMessage();
  const [form, setForm] = useState<ResetPasswordProps>(initialFormState);

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    sendMessage(resetPassword(form.email));
  };

  const errors = validation((form: any));

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.header}>
          <h2>{loginview.forgotPasswordHeader ?? ""}</h2>
          <Button to="/" type="button">
            <CloseIcon className={styles.closeIcon} />
          </Button>
        </div>
        <p>{loginview.forgotPasswordText ?? ""}</p>
        <Form
          value={(form: any)}
          errors={errors}
          onError={focusInvalidField}
          onSubmit={submit}
          onChange={(x: FormData) => setForm({ ...form, ...(x: any) })}
        >
          <div className={styles.input}>
            <Field type="text" name="email" label={t("RESET_PASSWORD.EMAIL")} />
          </div>

          <Button
            type="submit"
            className={styles.button}
            loading={false}
            variant="secondary"
          >
            {t("RESET_PASSWORD.SUBMIT")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordView;
